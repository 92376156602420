:root {
  --primary-color: white;
  --secondary-color: #0088a6;
  --blue-lis: #092e3b;
  --lis-ligthblue: #00b1e2;
  --aedas-green: #00b176;
  --toastify-color-success: #00b176 !important;
  --toastify-color-error: #b1003b !important;
  --aedas-red: #b1003b;
  --font-pay: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  --lis-title: "lis-title";
  --lis-body: "lis-body";
  --iconColor: black;
  --iconButtonSelected: #0088a6;
}

#root > div.row > div.w-100 > div > button > svg > path{
  fill:white !important
}


body {
  font-family: var(--lis-title), var(--lis-body);
  background-color: #8080801a !important;
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: "lis-body";
  src: url(/public/Assets/Fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "lis-title";
  src: url(/public/Assets/Fonts/WorkSans-Medium.ttf);
}

/* .row {
  --bs-gutter-x: 0 !important;
}

.btn_submit{
  color:white !important;
  background-color: var(--blue-lis)!important;
} */

.page-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 5rem;
  text-transform: uppercase;
}

.button {
  font-family: var(--lis-title) !important;
  font-size: 1rem !important;
  padding: 0.5rem 1rem !important;
  margin: 1rem auto 0 1rem!important;
  width: fit-content;
  text-align: center;
}

.blue-button {
  background-color: var(--blue-lis) !important;
  color: white !important;
  margin-right: 1rem !important;
}

.grey-button {
  background-color: #e3e3e3!important;
  color: black !important;
}

.button.blue-button.is-loading {
    color: white !important;
    background-color: #e0e0e0 !important;
    padding-left: 2.5rem !important;
}

.reject-button {
  margin-right: 1rem !important;
  background-color: white !important;
  color: var(--aedas-red) !important;
  border: 2px solid var(--aedas-red) !important;
}

.MuiDataGrid-cell--withRenderer button.grey-button {
  padding: 0 !important;
  margin: 0 !important;
}

.outlined-button {
  background-color: white !important;
  color: var(--blue-lis) !important;
}

.MuiDataGrid-main {
  font-family: var(--lis-title);
}

#corporate-form .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.MuiInputBase-adornedEnd {
  padding-right: 1.5rem;
}

#report label.MuiFormLabel-root.MuiInputLabel-root,
#tab-component-detail.milestone-edit label.MuiFormLabel-root.MuiInputLabel-root,
#corporate-form.text-center.milestone-edit label.MuiFormLabel-root.MuiInputLabel-root {
  color: #000;
  font-family: var(--lis-title);
}

#report input.MuiInputBase-input.MuiInput-input,
#tab-component-detail.milestone-edit input.MuiInputBase-input.MuiInput-input,
#corporate-form.text-center.milestone-edit input.MuiInputBase-input.MuiInput-input {
  -webkit-text-fill-color: black;
  font-family: var(--lis-title);
}

#corporate-form.text-center.milestone-edit input.MuiInputBase-input.MuiInput-input:after {
  border-color: transparent !important;
}

#corporate-form.text-center.milestone-edit svg.MuiSvgIcon-root {
  fill: #ccc !important;
}

@media screen and (min-width: 1680px) {
  .main-container {
    max-width: calc(1680px * 0.9);
    margin: 0 auto;
  }
} 

@media print {
  html, body {
    width: 210mm;
    height: 297mm;
    margin: 10mm;
  }


  .header-container,
  .button.grey-button.print,
  .button.blue-button.print {
    display: none !important;
  }

  .app-container {
    overflow: visible !important;
    width: 100vw !important;
  }

  .report-container {
    width: 100vw !important;
    margin: 0;
    margin-left: -70px;
    overflow: visible !important;
  }

  #report.MuiBox-root {
    width: 100vw;
    overflow: visible !important;
  }

  .fields-group {
    max-width: calc(100vw - 2cm) !important;
    width: 100%;
    page-break-inside: avoid;
  }

  #members-table-box {
    max-width: 100vw !important;
  }

  .report-container .Mui-resizeTriggers {
    display: none;
  }

  * {
    print-color-adjust: exact; 
    overflow: visible !important;

  }

  @page {
    size: A4 landscape ;
    margin: 2cm;
    zoom: 40%;
    
  }

}
