#info-corporate {
  display: contents;
}

#info-corporate .react-tabs__tab-list {
    display: flex;
    max-width: calc(100% - 70px - 2rem);
    flex-wrap: nowrap;
    margin: 1rem auto 1.5rem;
    width: 90%;
}

#info-corporate .react-tabs__tab {
    background-color: #092e3bad;
    color: white;
    font-family: var(--lis-title);
    text-align: center;
    border-radius: 0px !important;
    border-color: auto !important;
    width: 200px;
    margin-right: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

  }
  
  #info-corporate .react-tabs__tab--selected {
    background-color: var(--blue-lis) !important;
    color: white !important;
    font-family: var(--lis-title) !important;
    border-radius: 0px !important;
    border-color: none !important;
    text-align: center;
    width: 200px;
    margin-right: 20px;
  }

#info-corporate .react-tabs {
    height: 100%;
    display: contents;
  }
  
#info-corporate .react-tabs__tab-panel {
    height: 100%;
    display: contents;
}

.general-info-corporate {
  width: 90%;
  margin: 0 auto;
}

.modal-field-title {
  font-size: 1rem;
  font-weight: bold;
  text-decoration: underline;
}

ul.documents-list {
  padding-left: 0 !important;
}

ul.documents-list li {
  list-style-type: none;
  margin: 0.5rem 0;
}

ul.documents-list li::before {
  content: attr(data-icon);
  font-size: 0.8em;
}

ul.documents-list li a {
  color: var(--blue-lis);
  text-decoration: none;
  cursor: pointer;
}

ul.documents-list li .dates {
  display: block;
  margin-left: 2rem;
}

ul.documents-list li a:hover {
  opacity: 1;
}

a.document-button {
  padding: 0.1rem .5rem;
  border: 1px solid grey;
  margin: 0 .5rem;
  border-radius: 5px;
  font-size: .8rem;
  opacity: 0.75;
  font-family: var(--lis-title);
  text-transform: uppercase;
}

a.document-button.view {
  margin-left: 1rem;
}

.button.blue-button.modal {
  margin: 0rem 1rem !important;
  padding: 0.15rem 0.5rem !important;
}

.button.blue-button.modal.is-loading {
  color: white !important;
  background-color: #e0e0e0 !important;
  padding-left: 2.5rem !important;
}

.button.blue-button.historical {
  margin: auto !important;
}


@media screen and (max-width: 1024px) {
    #info-corporate .react-tabs__tab-list {
        flex-wrap: wrap;
    }
}