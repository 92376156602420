.report-container {
    background-color: #fff;
    padding: 2rem;
    /* margin: 3rem; */
    /* margin-right: 0; */
    width: calc(90% - 70px);
    text-align: center;
    max-width: 1100px;
    margin-left: calc((100% - 1100px - 70px) / 2);
}

h3.report-title {
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
    /* margin-bottom: 2rem; */
    text-transform: uppercase;
}

.fields-group {
    /* outline: .5px solid #8c8c8c; */
    border: .5px solid #8c8c8c ;
    border-radius: 4px;
    width: 90%;
    margin: 0 auto 4rem;

}

.fields-group p {
    margin: 1rem 0 0 1rem;
    text-align: left;
    page-break-inside: avoid;
}


#report .fields-group label.MuiFormLabel-root.MuiInputLabel-root {
    color: #8c8c8c;
}

#report .fields-group input.MuiInputBase-input.MuiInput-input {
    text-transform: uppercase;
    color: #000 !important;
}

.fields-group > div:first-child {
    background-color: #e0e0e0;
    display: flex;
    padding: 1rem;
    justify-content: space-between;
}

.fields-group > div:first-child p {
    margin: 0;
    margin-bottom: 1rem;
}



#report #members-table-box > div,
#report #partners-table-box > div,
#report #milestone-table-box > div {
    margin: 2rem 1rem !important;
    width: auto !important;
}

#report #members-table-box .MuiDataGrid-main .MuiDataGrid-columnHeaderTitleContainer,
#report #partners-table-box .MuiDataGrid-main .MuiDataGrid-columnHeaderTitleContainer,
#report #milestone-table-box .MuiDataGrid-main .MuiDataGrid-columnHeaderTitleContainer {
    justify-items: flex-start !important;
}

#report #members-table-box .MuiDataGrid-main .MuiDataGrid-columnHeadersInner,
#report #partners-table-box .MuiDataGrid-main .MuiDataGrid-columnHeadersInner,
#report #milestone-table-box .MuiDataGrid-main .MuiDataGrid-columnHeadersInner {
    background-color: #fafafa;
}

#report .MuiDataGrid-footerContainer {
    display: none;
}

#report .MuiDataGrid-columnHeaderTitle {
    white-space: break-spaces;
    font-weight: 500;
    word-break: break-word;
    line-height: 1.3em;
    padding: 0 5px;
}

.report-container .MuiSvgIcon-root {
    fill: #fff !important;
}

@media screen and ( max-width: 1280px) and (min-width: 769px) {
    .report-container {
        max-width: 80%;
        margin-left: calc((20% - 70px) / 2);
    }
}