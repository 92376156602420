.main-container {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(224, 224, 224, 1);
    border-radius: 4px;
    width: 90%;
    margin: auto 2rem;
}

.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: center !important;
}

.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell {
    justify-content: center;
}

.MuiDataGrid-cell--textLeft {
    justify-content: center !important;
}

.autocomplete.coporate {
    display: inline;
    width: 100%;
}

.button-container {
    display: inline-flex;
    margin-top: 0;
    margin-bottom: 16px;
    margin-left: 32px;
    margin-right: 32px;
    min-width: 40%;
    width: fit-content;
}

.button-container.new-corporate {
    margin: 0 auto !important;
    min-width: 0;
}

#filter-buttons.button-container {
    margin: 0;
}

#filter-buttons.button-container button {
    margin-top: 0 !important;
    height: 2.5em;
    align-self: center;
}

#filter-buttons.button-container button:first-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

#filter-buttons.button-container button:last-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.section-container {
    display: flex;
    align-items: flex-end;
}

.grey-box {
    background-color: #d9d9d9;
    padding: 2rem;
    margin: 1rem;
    border-radius: 8px;
    border: 1px solid #8c8c8c;
}

.grey-box.capital {
    margin-bottom: 2rem;
    width: calc(100% - 1rem);
}

.grey-box > h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
    text-align: center;
}

.grey-box.capital h4 {
    text-align: center;
}

.section-container button.button.blue-button {
    margin: 0 auto !important;
}

.juridical-inputs {
    display: flex;
}

.natural-inputs {
    display: flex;
    flex-direction: column;
}

.juridical-container .natural-inputs {
    display: none;
}

.natural-container .juridical-inputs {
    display: none;
}

.MuiFormControl-root > .MuiFormLabel-root.Mui-fucused {
    color: rgba(0, 0, 0, 0.6) !important;
}

.MuiFormControlLabel-root.check-box-label {
    display: flex;
}

.MuiTypography-root.MuiFormControlLabel-label,
.MuiFormControlLabel-root.check-box-label span:last-child { 
   font-size: 0.8rem;
   text-align: center;
   color: rgba(0, 0, 0, 0.6); 
   font-family: "Roboto","Helvetica","Arial",sans-serif !important;
}

.corporation-details-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 0.5rem;
}

.corporation-details-box  p {
    font-size: 0.8rem;
    outline: rgb(140, 140, 140) solid 0.8px;
    padding: 0.5rem;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0.5rem;
}

.button.grey-button.shares,
.button.blue-button.shares {
    font-size: 0.8rem !important;
    margin: 0.3rem !important;
    height: 1.8em;
}

.button.blue-button.document-download {
    height: 3.5em;
    margin-right: 1rem !important;
    font-size: 0.8rem !important;
    padding: 10px !important;
}

.MuiSlider-root.MuiSlider-colorPrimary {
    color: #8c8c8c;
}

.shares-detail-container span:not(:last-child):not(:first-child)::after {
    content: ' | ';
    color: #00000042;
    font-weight: 100;
}

div.margin-left-0 {
    margin-left: 0 !important;
}

div.margin-right-0 {
    margin-right: 0 !important;
}

.person-inputs {
    display: flex;
    flex-direction: column;
    outline: 1px solid rgba(9, 46, 59, 0.2);
    padding: 0.5rem;
    border-radius: 4px;
    margin: 1rem;
}


.MuiSwitch-root .Mui-checked+.MuiSwitch-track {
    background-color:  rgba(9, 46, 59, 0.9) !important;
}

.MuiSwitch-root span.Mui-checked .MuiSwitch-thumb {
    color:  rgb(9, 46, 59);
}

input.MuiInputBase-input.MuiOutlinedInput-input {
    min-height: 2rem !important;
    height: auto !important;
}

div.milestone-date input.MuiInputBase-input.MuiOutlinedInput-input {
    height: 62px !important;
}

mapbox-address-autofill {
    width: 100% !important;
}